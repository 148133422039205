<template>
	<div class="cards-row">
		<h2 v-if="title">{{ title }}</h2>

		<div class="d-flex justify-content-between flex-wrap a-cards-wrapper" v-if="cards && cards.length">
			<z-card
				v-for="card in cards"
				:key="card.id"
				:image="card.image ? card.image.path : null"
				:tags="FEATURE.coursesTags ? card.tags : null"
				:title="card.name"
				:description="card.description || card.short_description"
				:url="card.url || card._url"
			>	
				{{ card.author }}
				<template slot="footer-fluid">
					
					<div v-if="FEATURE.coursesAuthor && (card.author && card.author.username || card.author)" class="d-flex justify-content-start align-items-center author">
						<img :src="(card.author && card.author.avatar && card.author.avatar.path) ? card.author.avatar.path : require('./img/avatar.png')">
						<p>{{ card.author.username || card.author }}</p>
					</div>

					<div v-if="card.status">
						<div v-if="card.status == 'approved'" class="project-status approved">Schválený</div>
						<div v-if="card.status == 'rejected'" class="project-status rejected">Projekt bol zamietnutý!</div>
						<div v-if="card.status == 'waiting'" class="project-status waiting">Čaká sa na schválenie</div>
					</div>
				</template>

				<template slot="footer-left">
					<div v-if="card.isPath">
						Learning J.
					</div>
				</template>

				<template slot="footer-right">
					<div v-if="FEATURE.coursesStars && isLoggedIn">
						<b-icon v-if="card.isStarredByActiveUser " icon="star-fill" font-scale="1.5" @click.prevent="toggleCourseStar(card.id, 0)"/>
						<b-icon v-else icon="star" font-scale="1.5" @click.prevent="toggleCourseStar(card.id, 1)"/>
					</div>
				</template>
			</z-card>
			<div class="fake-card" v-for="index in 3 - (cards.length % 3 || 3)" :key="`fake-card-${index}`"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		title: {
			type: String,
			required: false
		},
		cards: {
			type: Array,
			required: true
		}
	},
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		])
	},
	methods: {
		toggleCourseStar(courseId, value) {
			this.$emit('course-star-toggled', courseId, value)
		}
	}
}
</script>

<style lang="scss" scoped>
.cards-row {
	padding-bottom: 40px;

	h2 {
		color: var(--ion-color-secondary);
	}

	.fake-card {
		width: 354px;
	}
}

.project-status {
	padding-top: 20px;

	&.approved {
		color: rgb(21, 179, 0);
	}

	&.rejected {
		color: rgb(223, 12, 12);
	}

	&.waiting {
		color: rgb(0, 137, 228);
	}
}

.author {
	img {
		height: 22px;
		width: 22px;
		border-radius: 100%;
		margin-right: 15px;
	}

	p {
		margin-bottom: 3px;
		font-size: 14px;
	}
}
</style>
